.formosa-toast-container {
	align-items: flex-end;
	bottom: $formosa-toast-bottom;
	display: flex;
	flex-direction: column;
	pointer-events: none;
	position: fixed;
	right: $formosa-toast-right;
	z-index: $formosa-toast-zindex;
}

.formosa-toast {
	align-items: center;
	animation-fill-mode: forwards;
	animation-name: formosa-slide-in-out;
	background-color: $formosa-primary-background-color;
	color: $formosa-primary-color;
	display: flex;
	margin: $formosa-toast-margin;
	max-width: 90vw;
	pointer-events: auto;
}

.formosa-toast__text {
	padding: $formosa-toast-padding;
}

.formosa-toast__close {
	background-color: transparent;
	border: 0;
	cursor: pointer;
	font-size: 0;
	height: 32px;
	margin: 0 8px 0 0;
	padding: 0;
	width: 32px;

	path {
		fill: $formosa-primary-color;
	}

	&:hover,
	&:active,
	&:focus {
		background-color: transparent;
	}
}

.formosa-toast--error {
	background-color: $formosa-toast-error-background-color;
	color: $formosa-toast-error-color;

	.formosa-toast__close {
		path {
			fill: $formosa-toast-error-color;
		}
	}
}

.formosa-toast--success {
	background-color: $formosa-toast-success-background-color;
	color: $formosa-toast-success-color;

	.formosa-toast__close {
		path {
			fill: $formosa-toast-success-color;
		}
	}
}

.formosa-toast--warning {
	background-color: $formosa-toast-warning-background-color;
	color: $formosa-toast-warning-color;

	.formosa-toast__close {
		path {
			fill: $formosa-toast-warning-color;
		}
	}
}

@keyframes formosa-slide-in-out {
	0% {
		transform: translateX(200%) translateZ(0);
	}

	10% {
		transform: translateX(0%) translateZ(0);
	}

	90% {
		transform: translateX(0%) translateZ(0);
	}

	100% {
		transform: translateX(200%) translateZ(0);
	}
}
