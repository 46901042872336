input.formosa-field__input--password {
	&.formosa-field__input {
		width: calc(100% - #{$formosa-password-button-width});
	}
}

.formosa-button--toggle-password {
	padding-left: 0;
	padding-right: 0;
	width: $formosa-password-button-width;
}
