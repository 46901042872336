.formosa-select-wrapper {
	display: table;
	position: relative;
}

.formosa-field__input--select {
	appearance: none;
	cursor: pointer;
	padding-right: $formosa-select-padding-right;

	.formosa-field--disabled & {
		cursor: default;
	}
}

.formosa-icon--caret {
	height: $formosa-select-arrow-size;
	margin-top: #{$formosa-select-arrow-size * -.5};
	position: absolute;
	right: #{$formosa-input-padding + $formosa-input-border-width};
	top: 50%;
	width: $formosa-select-arrow-size;

	path {
		fill: $formosa-input-color;
	}
}
