.formosa-suffix-container {
	position: relative;
}

.formosa-suffix {
	border-color: transparent;
	border-style: solid;
	border-width: $formosa-input-border-width 0;
	bottom: 0;
	padding: $formosa-input-padding;
	position: absolute;
	right: #{$formosa-input-border-width * -1};
	top: 0;
	z-index: 1;
}
