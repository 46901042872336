.formosa-label-wrapper {
	display: block;
	margin-bottom: $formosa-label-margin;

	&:empty {
		display: none;
	}
}

.formosa-label {
	font-weight: $formosa-label-font-weight;

	&[for] {
		cursor: pointer;

		.formosa-field--disabled & {
			cursor: default;
		}
	}

	.formosa-field--has-error & {
		color: $formosa-label-error-color;
	}
}

.formosa-radio__label {
	cursor: pointer;

	.formosa-field--disabled & {
		cursor: default;
	}
}

.formosa-label--required {
	&::after {
		content: ' *';
	}
}

.formosa-field--label-after {
	.formosa-label-wrapper {
		display: inline-block;
		margin: 0;

		&:empty {
			display: none;
		}
	}
}
