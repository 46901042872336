.formosa-alert {
	background-color: $formosa-alert-primary-background-color;
	color: $formosa-alert-primary-color;
	padding: $formosa-alert-padding;

	h1 + & {
		margin-top: $formosa-margin-large;
	}
}

.formosa-alert--error {
	background-color: $formosa-alert-error-background-color;
	color: $formosa-alert-error-color;
}

.formosa-alert--success {
	background-color: $formosa-alert-success-background-color;
	color: $formosa-alert-success-color;
}

.formosa-alert--warning {
	background-color: $formosa-alert-warning-background-color;
	color: $formosa-alert-warning-color;
}
