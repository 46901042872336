@mixin formosa-horizontal() {
	display: table;
	width: 100%;

	& > .formosa-field {
		display: table-row;

		& > .formosa-label-wrapper,
		& > .formosa-input-wrapper {
			display: table-cell;
			padding: $formosa-horizontal-fields-padding 0;
		}

		& > .formosa-label-wrapper {
			padding-right: $formosa-horizontal-fields-padding;
			text-align: right;
			vertical-align: top;
			white-space: nowrap;
			width: 0;
		}

		& > .formosa-input-wrapper {
			padding-left: $formosa-horizontal-fields-padding;
			width: 100%;

			& > .formosa-icon--check {
				left: #{($formosa-checkbox-padding * .5) + $formosa-horizontal-fields-padding};
				top: #{($formosa-checkbox-padding * .5) + $formosa-horizontal-fields-padding};
			}
		}

		.formosa-label {
			display: inline-block;
			line-height: $formosa-input-line-height;
			padding-left: 0;
			padding-right: 0;
			padding-top: #{$formosa-input-padding + $formosa-input-border-width};
		}

		.formosa-radio--inline {
			padding-top: #{$formosa-input-padding + $formosa-input-border-width};
		}
	}

	& > .formosa-field--label-after {
		.formosa-label-wrapper {
			&:empty {
				display: inline-block;
			}
		}
	}
}

.formosa-horizontal {
	@include formosa-horizontal;
}

.formosa-responsive {
	@media (width >= $formosa-responsive-breakpoint) {
		@include formosa-horizontal;
	}
}
