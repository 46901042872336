.formosa-field--disabled {
	& > .formosa-input-wrapper {
		opacity: $formosa-disabled-opacity;
	}

	// Undo iOS Safari styles.
	input:disabled,
	select:disabled,
	textarea:disabled {
		opacity: 1;
	}

	input[type='file']:disabled {
		opacity: 0;
	}
}

.formosa-field__input,
.formosa-field__input--select,
.formosa-field__input--textarea,
.formosa-file-input-wrapper {
	background-color: $formosa-input-background-color;
	border: $formosa-input-border-width solid $formosa-input-border-color;
	border-radius: $formosa-input-border-radius;
	color: $formosa-input-color;
	font-family: $formosa-input-font-family;
	font-size: $formosa-input-font-size;
	padding: $formosa-input-padding;

	&:invalid {
		box-shadow: none;

		&:focus {
			box-shadow: 0 0 0 $formosa-focus-border-width $formosa-focus-box-shadow-color;
		}
	}

	.formosa-field--has-error & {
		border-color: $formosa-focus-error-border-color;

		&:focus {
			border-color: $formosa-focus-border-color;
		}
	}
}

.formosa-button,
.formosa-field__input,
.formosa-field__input--select,
.formosa-file-input-wrapper {
	line-height: $formosa-input-line-height;
}

.formosa-button,
.formosa-field__input,
.formosa-field__input--select,
.formosa-field__input--textarea {
	margin: 0;
}

.formosa-field__input,
.formosa-field__input--textarea {
	appearance: none; // So focus style works on iOS.
}

.formosa-field__input:not([size]) {
	box-sizing: border-box;
	width: 100%;
}
