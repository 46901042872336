.formosa-prefix {
	border-bottom-right-radius: 0;
	border-right-width: $formosa-prefix-border-width;
	border-top-right-radius: 0;

	&:focus {
		position: relative;
	}
}

.formosa-postfix {
	border-bottom-left-radius: 0;
	border-left-width: $formosa-postfix-border-width;
	border-top-left-radius: 0;

	&:focus {
		position: relative;
	}
}
