input.formosa-field__input--checkbox {
	appearance: none;
	border-radius: $formosa-checkbox-border-radius;
	cursor: pointer;
	height: $formosa-checkbox-size;
	margin: 0 $formosa-checkbox-margin 0 0;
	vertical-align: middle;

	&.formosa-field__input {
		width: $formosa-checkbox-size;
	}

	.formosa-field--disabled & {
		cursor: default;
	}
}

.formosa-input-wrapper--checkbox {
	position: relative;
}

.formosa-icon--check {
	bottom: 0;
	height: #{$formosa-checkbox-size - $formosa-checkbox-padding};
	left: #{$formosa-checkbox-padding * .5};
	pointer-events: none;
	position: absolute;
	top: #{$formosa-checkbox-padding * .5};
	width: #{$formosa-checkbox-size - $formosa-checkbox-padding};

	path {
		fill: transparent;
	}

	.formosa-field--disabled & {
		opacity: $formosa-disabled-opacity;
	}

	:checked + & path {
		fill: $formosa-input-color;
	}

	& + .formosa-label-wrapper {
		.formosa-label {
			font-weight: normal;
		}
	}
}
