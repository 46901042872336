.formosa-radio {
	border: 0;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.formosa-radio__legend {
	bottom: 0;
	clip: rect(1px, 1px, 1px, 1px);
	font-size: 0;
	margin: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
}

.formosa-radio--inline {
	display: flex;
	flex-wrap: wrap;

	.formosa-radio__item {
		margin-bottom: $formosa-radio-margin;
		margin-right: $formosa-radio-margin-inline;
	}
}

.formosa-radio__item {
	position: relative;
}

.formosa-radio__item + .formosa-radio__item {
	margin-top: $formosa-radio-margin;

	.formosa-radio--inline & {
		margin-top: 0;
	}
}

input.formosa-radio__input {
	appearance: none;
	border-color: $formosa-input-background-color;
	border-radius: $formosa-radio-border-radius;
	border-width: $formosa-radio-border-width;
	box-shadow: 0 0 0 $formosa-input-border-width $formosa-input-border-color;
	cursor: pointer;
	height: $formosa-radio-size;
	line-height: $formosa-input-line-height;
	margin: 0 $formosa-radio-margin-side 0 0;
	padding: 0;
	vertical-align: middle;

	.formosa-field--disabled & {
		cursor: default;
	}

	&.formosa-field__input {
		width: $formosa-radio-size;
	}

	&:checked {
		background-color: $formosa-input-color;
	}

	&:focus {
		box-shadow:
			0 0 0 $formosa-input-border-width $formosa-focus-border-color,
			0 0 0 #{$formosa-input-border-width + $formosa-focus-border-width} $formosa-focus-box-shadow-color;
	}
}
