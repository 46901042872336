$formosa-color-danger: #f66 !default;

$formosa-primary-background-color: #9bc !default;
$formosa-primary-color: #000 !default;

$formosa-error-background-color: $formosa-color-danger !default;
$formosa-error-color: #000 !default;

$formosa-success-background-color: #6c6 !default;
$formosa-success-color: #000 !default;

$formosa-warning-background-color: #f96 !default;
$formosa-warning-color: #000 !default;

$formosa-dark-mode: false !default;

$formosa-disabled-opacity: .5 !default;

$formosa-input-background-color: if($formosa-dark-mode, #222, #fff) !default;
$formosa-input-border-color: if($formosa-dark-mode, #555, #ccc) !default;
$formosa-input-border-radius: 0 !default;
$formosa-input-border-width: 1px !default;
$formosa-input-color: if($formosa-dark-mode, #fff, #000) !default;
$formosa-input-font-family: sans-serif !default;
$formosa-input-font-size: 18px !default;
$formosa-input-padding: 8px !default;
$formosa-input-line-height: 1.5 !default;
// stylelint-disable-next-line stylistic/max-line-length
$formosa-input-height: ($formosa-input-border-width * 2) + ($formosa-input-padding * 2) + ($formosa-input-font-size * $formosa-input-line-height) !default;
$formosa-input-height: if($formosa-input-height % 2 == 0, $formosa-input-height, $formosa-input-height - 1); // Ensure height is even.

$formosa-margin-large: 24px !default;

$formosa-autocomplete-clear-size: $formosa-input-height - ($formosa-input-border-width * 2) !default;
$formosa-autocomplete-clear-color: if($formosa-dark-mode, #ddd, #333) !default;
$formosa-autocomplete-clear-color-hover: if($formosa-dark-mode, #fff, #000) !default;

$formosa-autocomplete-options-background-color: scale-color($formosa-input-background-color, $lightness: 10%) !default;
$formosa-autocomplete-options-background-color-highlighted: scale-color($formosa-input-background-color, $lightness: 25%) !default;
$formosa-autocomplete-options-border-color: scale-color($formosa-autocomplete-options-background-color, $lightness: 15%) !default;

$formosa-autocomplete-value-background-color: scale-color($formosa-input-background-color, $lightness: 10%) !default;
$formosa-autocomplete-value-padding-top: 8px !default;
$formosa-autocomplete-value-margin: 4px !default;
$formosa-autocomplete-value-height: ($formosa-autocomplete-value-padding-top * 2) + $formosa-input-font-size !default;

$formosa-button-background-color: $formosa-primary-background-color !default;
$formosa-button-background-color-hover: scale-color($formosa-button-background-color, $lightness: -15%) !default;
$formosa-button-color: $formosa-primary-color !default;
$formosa-button-border-color: $formosa-button-background-color !default;
$formosa-button-font-weight: normal !default;
$formosa-button-padding: $formosa-input-padding !default;
$formosa-button-padding-side: 12px !default;

$formosa-button-danger-background-color: $formosa-error-background-color !default;
$formosa-button-danger-background-color-hover: scale-color($formosa-button-danger-background-color, $lightness: -15%) !default;
$formosa-button-danger-border-color: $formosa-button-danger-background-color !default;
$formosa-button-danger-color: $formosa-error-color !default;

$formosa-button-warning-background-color: $formosa-warning-background-color !default;
$formosa-button-warning-background-color-hover: scale-color($formosa-button-warning-background-color, $lightness: -15%) !default;
$formosa-button-warning-border-color: $formosa-button-warning-background-color !default;
$formosa-button-warning-color: $formosa-warning-color !default;

$formosa-button-success-background-color: $formosa-success-background-color !default;
$formosa-button-success-background-color-hover: scale-color($formosa-button-success-background-color, $lightness: -15%) !default;
$formosa-button-success-border-color: $formosa-button-success-background-color !default;
$formosa-button-success-color: $formosa-success-color !default;

$formosa-checkbox-size: 32px !default;
$formosa-checkbox-margin: 8px !default;
$formosa-checkbox-padding: 12px !default;
$formosa-checkbox-border-radius: 0 !default;

$formosa-horizontal-fields-padding: 8px !default;

$formosa-field-error-color: $formosa-color-danger !default;

$formosa-focus-border-color: #4ad !default;
$formosa-focus-border-width: 3px !default;
$formosa-focus-box-shadow-color: #6cf !default;
$formosa-focus-error-border-color: $formosa-color-danger !default;

$formosa-label-error-color: $formosa-color-danger !default;
$formosa-label-font-weight: bold !default;
$formosa-label-margin: 4px !default;

$formosa-alert-error-background-color: $formosa-error-background-color !default;
$formosa-alert-error-color: $formosa-error-color !default;
$formosa-alert-padding: 16px !default;
$formosa-alert-primary-background-color: $formosa-primary-background-color !default;
$formosa-alert-primary-color: $formosa-primary-color !default;
$formosa-alert-success-background-color: $formosa-success-background-color !default;
$formosa-alert-success-color: $formosa-success-color !default;
$formosa-alert-warning-background-color: $formosa-warning-background-color !default;
$formosa-alert-warning-color: $formosa-warning-color !default;

$formosa-note-font-size: 85% !default;
$formosa-note-line-height: 1.4 !default;
$formosa-note-opacity: .75 !default;
$formosa-note-margin: 4px 0 0 !default;

$formosa-password-button-width: 70px !default;

$formosa-prefix-border-width: 0 !default;
$formosa-postfix-border-width: 0 !default;

$formosa-radio-border-width: 6px !default;
$formosa-radio-border-radius: 100px !default;
$formosa-radio-margin: 8px !default;
$formosa-radio-margin-inline: 16px !default;
$formosa-radio-margin-side: $formosa-checkbox-margin !default;
$formosa-radio-size: $formosa-checkbox-size !default;

$formosa-responsive-breakpoint: 1024px !default;

$formosa-search-icon-size: 24px !default;

$formosa-select-arrow-size: 12px !default;
$formosa-select-padding-right: $formosa-select-arrow-size + ($formosa-input-padding * 2) !default;

$formosa-spinner-border-width: 6px !default;
$formosa-spinner-color: transparent !default;
$formosa-spinner-color-alternate: #fff !default;
$formosa-spinner-fullscreen-border-width: 12px !default;
$formosa-spinner-fullscreen-size: 50px !default;
$formosa-spinner-size: $formosa-input-height - ($formosa-spinner-border-width * 2) !default;

$formosa-toast-bottom: 0 !default;
$formosa-toast-error-background-color: $formosa-error-background-color !default;
$formosa-toast-error-color: $formosa-error-color !default;
$formosa-toast-margin: 8px !default;
$formosa-toast-padding: 16px !default;
$formosa-toast-right: 0 !default;
$formosa-toast-success-background-color: $formosa-success-background-color !default;
$formosa-toast-success-color: $formosa-success-color !default;
$formosa-toast-warning-background-color: $formosa-warning-background-color !default;
$formosa-toast-warning-color: $formosa-warning-color !default;

$formosa-spinner-zindex: 9999 !default;
$formosa-toast-zindex: 9998 !default;
$formosa-autocomplete-zindex: 9996 !default;
