.formosa-button {
	background-color: $formosa-button-background-color;
	border: $formosa-input-border-width solid $formosa-button-border-color;
	border-radius: $formosa-input-border-radius;
	color: $formosa-button-color;
	cursor: pointer;
	font-family: $formosa-input-font-family;
	font-size: $formosa-input-font-size;
	font-weight: $formosa-button-font-weight;
	line-height: $formosa-input-line-height;
	padding: $formosa-button-padding $formosa-button-padding-side;

	&:hover,
	&:active,
	&:focus {
		background-color: $formosa-button-background-color-hover;
	}

	&[disabled] {
		cursor: default;

		&:hover,
		&:active,
		&:focus {
			background-color: $formosa-button-background-color;
		}
	}

	path {
		fill: $formosa-button-color;
	}
}

.formosa-button--danger {
	background-color: $formosa-button-danger-background-color;
	border-color: $formosa-button-danger-border-color;
	color: $formosa-button-danger-color;

	&:hover,
	&:active,
	&:focus {
		background-color: $formosa-button-danger-background-color-hover;
	}

	&[disabled] {
		&:hover,
		&:active,
		&:focus {
			background-color: $formosa-button-danger-background-color;
		}
	}
}

.formosa-button--success {
	background-color: $formosa-button-success-background-color;
	border-color: $formosa-button-success-border-color;
	color: $formosa-button-success-color;

	&:hover,
	&:active,
	&:focus {
		background-color: $formosa-button-success-background-color-hover;
	}

	&[disabled] {
		&:hover,
		&:active,
		&:focus {
			background-color: $formosa-button-success-background-color;
		}
	}
}

.formosa-button--warning {
	background-color: $formosa-button-warning-background-color;
	border-color: $formosa-button-warning-border-color;
	color: $formosa-button-warning-color;

	&:hover,
	&:active,
	&:focus {
		background-color: $formosa-button-warning-background-color-hover;
	}

	&[disabled] {
		&:hover,
		&:active,
		&:focus {
			background-color: $formosa-button-warning-background-color;
		}
	}
}
