.formosa-spinner {
	align-content: center;
	align-items: center;
	align-self: center;
	display: flex;
	font-size: 0;

	&::before {
		animation-duration: 1s;
		animation-iteration-count: infinite;
		animation-name: formosa-spin;
		animation-timing-function: linear;
		border-color: $formosa-spinner-color $formosa-spinner-color-alternate $formosa-spinner-color-alternate $formosa-spinner-color-alternate;
		border-radius: 100px;
		border-style: solid;
		border-width: $formosa-spinner-border-width;
		content: '';
		display: block;
		height: $formosa-spinner-size;
		width: $formosa-spinner-size;
	}
}

.formosa-spinner--fullscreen {
	inset: 0;
	justify-content: center;
	position: fixed;
	z-index: $formosa-spinner-zindex;

	&::before {
		border-width: $formosa-spinner-fullscreen-border-width;
		height: $formosa-spinner-fullscreen-size;
		width: $formosa-spinner-fullscreen-size;
	}
}

@keyframes formosa-spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
