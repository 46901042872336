$formosa-dark-mode: true;

@import '../../src/style';

$background-color: if($formosa-dark-mode, #000, #fff);
$color: if($formosa-dark-mode, #fff, #000);
$margin: 20px;
$content-width: 700px;
$sidebar-width: 400px;
$breakpoint: $content-width + $sidebar-width + 20px;

body {
	background-color: $background-color;
	color: $color;
	font-family: $formosa-input-font-family;
	font-size: $formosa-input-font-size;
	margin: $margin;
}

main {
	margin: 0 auto;
	max-width: $content-width;

	@media (width >= $breakpoint) {
		padding-right: $sidebar-width;
	}
}

#header {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

#title {
	font-size: 48px;
	font-weight: bold;
	margin: 0;
}

h1 {
	font-size: 28px;
}

h2 {
	font-size: 20px;
}

h1,
h2 {
	margin-bottom: 12px;
	margin-top: 32px;
}

h1 + *,
h2 + * {
	margin-top: 0 !important; // stylelint-disable-line declaration-no-important
}

a {
	color: #0cf;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
}

#nav {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 -1px $margin;
	padding: 0;
}

.nav__item {
	flex: 1 1 auto;
	margin: 1px;
}

.nav__link {
	align-items: center;
	background-color: $formosa-primary-background-color;
	color: $formosa-primary-color;
	display: flex;
	justify-content: center;
	min-height: 44px;
	padding: 8px;
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		background-color: scale-color($formosa-primary-background-color, $lightness: -10%);
	}
}

.nav__link--active {
	background-color: $background-color;
	color: $color;

	&:hover,
	&:active,
	&:focus {
		background-color: $background-color;
	}
}

.formosa-field__note {
	word-wrap: break-word;
}

.code {
	pre {
		background-color: #333;
		box-sizing: border-box;
		font-size: 16px;
		line-height: 1.5;
		margin: $margin 0 0;
		overflow: auto;
		padding: 12px;
		white-space: pre-wrap;

		&::before {
			content: 'Row:';
			display: block;
			font-family: sans-serif;
			font-weight: bold;
		}

		@media (width >= $breakpoint) {
			bottom: 0;
			margin: 0;
			position: fixed;
			right: 0;
			top: 0;
			width: $sidebar-width;
			z-index: 999;
		}
	}
}
