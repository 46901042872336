.formosa-file-wrapper {
	display: flex;
}

.formosa-file-input-wrapper {
	flex: 1 1 auto;
	position: relative;

	&:focus-within {
		border-color: $formosa-focus-border-color;
		box-shadow: 0 0 0 $formosa-focus-border-width $formosa-focus-box-shadow-color;
	}
}

.formosa-field__input--file {
	background-color: transparent;
	border: 0;
	cursor: pointer;
	font-size: 0;
	inset: 0;
	opacity: 0;
	padding: 0;
	position: absolute;

	.formosa-field--disabled & {
		cursor: default;
		opacity: 0;
		pointer-events: none;
	}
}

.formosa-file-name {
	word-break: break-word;
}

.formosa-file-name--empty {
	opacity: .5;
}

.formosa-file-link {
	display: inline-block;
	font-size: 0;
}

.formosa-file-image {
	max-width: 100%;
}
