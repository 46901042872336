.formosa-search-wrapper {
	display: table;
	position: relative;
	width: 100%;
}

input.formosa-field__input--search {
	appearance: none;
	padding-right: #{$formosa-search-icon-size + ($formosa-input-padding * 2)};
}

.formosa-icon--search {
	height: $formosa-search-icon-size;
	margin-top: #{$formosa-search-icon-size * -.5};
	pointer-events: none;
	position: absolute;
	right: $formosa-input-padding;
	top: 50%;
	width: $formosa-search-icon-size;

	path {
		fill: $formosa-input-color;
	}
}
